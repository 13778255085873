<template>
    <div>
        <v-bottom-navigation
            :value="value"
            color="teal"
            grow
        >
        <v-btn v-if="value === 0" >
            <span class="activeClassText">Home</span>
            <img
            src= "https://s3iconimages.mymedicine.com.mm/Home_doc.svg" class="activeClassIcon"
            />
        </v-btn>
        <v-btn v-else @click="redirectToHomePage">
            <span class="defaultClassText">Home</span>
            <img
            src="https://s3iconimages.mymedicine.com.mm/Home_doc.svg" class="activeClassIcon"
            />
        </v-btn>

        <v-btn v-if="value === 1" >
            <span class="activeClassText" style=" width: 90px;  overflow: hidden; text-overflow: ellipsis;">Appointment History</span>
            <img
            src="https://s3iconimages.mymedicine.com.mm/appointment_history.svg" class="activeClassIcon"
            />
        </v-btn>
        <v-btn v-else @click="redirectToAppointmentHistory" >
            <span class="defaultClassText" style=" width: 90px;  overflow: hidden; text-overflow: ellipsis;">Appointment History</span>
            <img
            src="https://s3iconimages.mymedicine.com.mm/appointment_history.svg" class="activeClassIcon"
            />
        </v-btn>

        <v-btn v-if="value === 2" >
            <span class="activeClassText">Patient List</span>
            <img
            src="https://s3iconimages.mymedicine.com.mm/patients_list.svg" class="activeClassIcon"
            />
        </v-btn>
        <v-btn v-else @click="redirectToPatientList">
            <span class="defaultClassText">Patient List</span>
            <img
            src="https://s3iconimages.mymedicine.com.mm/patients_list.svg" class="activeClassIcon"
            />
        </v-btn>

        <v-btn v-if="value === 3">
            <span class="activeClassText">Profile</span>
            <img
            src="https://s3iconimages.mymedicine.com.mm/appointment_history.svg" class="activeClassIcon"
            />
        </v-btn>
        <v-btn v-else @click="redirectToProfile">
            <span class="defaultClassText">Profile</span>
            <img
            src="https://s3iconimages.mymedicine.com.mm/appointment_history.svg" class="activeClassIcon"
            />
        </v-btn>
        </v-bottom-navigation>
    </div>
</template>
<script>
export default{
    name: "DocBottomNavBar",
    props:['pageName'],
    data(){
        return{
            value:''
        }
    },
    mounted(){
        if (this.pageName === 'HomePage'){
            this.value = 0
        } else if (this.pageName === 'AppointmentHistory') {
            this.value = 1
        } else if (this.pageName === 'PatientList') {
            this.value = 2
        } else if (this.pageName === 'DoctorProfile') {
            this.value = 3
        }
    },
    methods:{
        redirectToHomePage() {
            this.$router.push({
                name: 'DoctorHome'
            });
        },
        redirectToAppointmentHistory() {
            this.$router.push({
                name: 'AppointmentHistory'
            })
        },
        redirectToPatientList() {
            this.$router.push({
                name: 'DoctorViewAllPatient'
            })
        },
        redirectToProfile() {
            this.$router.push({
                name: 'DoctorProfile'
            })
        }
    }

}
</script>
<style scoped>
.activeClassIcon {
    height: 2.5em;
}
.activeClassText{
    color: #48ACEF;
    font-size: 14px;
}
.defaultClassText{
    color: #333333;
    font-size: 14px;
}
</style>
